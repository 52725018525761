import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import DocumentationDetail from '../scenes/DocumentationDetail'
import Footer from '../scenes/Footer'

import headerPicture from '../img/artworks/artwork_documenation_header.png'

const PrivacyPolicy = ({ intl: { formatMessage }, data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout>
      <SEO
        metaTitle={formatMessage({ id: 'meta.privacyPolicy.title' })}
        metaDescription={formatMessage({ id: 'meta.privacyPolicy.description' })}
        metaOGImage={headerPicture}
      />
      <DocumentationDetail
        path="/privacy-policy"
        content={page.html}
      />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query GetPrivacyPolicy($locale: String!) {
    markdownRemark(
      frontmatter: { 
        lang: { eq: $locale } 
        name: { eq: "privacy-policy"}
      }
    ) {
      html
    }
  }
`

export default withContext(injectContext(withIntl(injectIntl(PrivacyPolicy))))
